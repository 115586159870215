import request from '@/router/axios';

// 我的申请列表
export const getMyList = () => request({
  url: '/api/blade-remote/dataList/myApply/telemedicine',
  method: 'get'
})
// 向我申请列表
export const getToMeList = () => request({
  url: '/api/blade-remote/dataList/applyToMe/telemedicine',
  method: 'get'
})
